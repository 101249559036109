import { useState } from "react"
import "./Cover.css"

export default function Cover ({setShowCover}) {
    const [openBook, setOpenBook] = useState(false)

    const onClick = () => {
        setOpenBook(true)
        setTimeout(() => {
            setShowCover(false)
        }, 2000)
    }

    return (
        <div className={`cover ${openBook && "open"}`}>
            <div className="texture-overlay"
                style={{background: `url(./img/texture-paper.png)`}}
            />
            <div className="logo-block">
                <div className="logo" 
                    style={{backgroundImage: `url(./img/logo-aisketch.svg)`}}
                />
                <div className="app-name">AISketch</div>
            </div>

            <div className="tag-line">
                Create Concept Art & Sketches with A.I.
                <div className="text">Designed for artists. Works best with pen & tablet.</div>
            </div>

            

            <button className="cover-button"
                onClick={onClick}
            >
                <span>Get Started</span>
                <div className="icon" 
                    style={{maskImage: `url(./img/icon-arrow.svg)`}}
                />
            </button>
        </div>
    )
}