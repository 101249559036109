import { drawStroke, drawImage, scaleCanvas } from "./CanvasUtils"
import { BLUR_EDGE } from "./Constants"

export const canvasMinScaleFactor = (width, height) => {
    const min = Math.min(width, height)
    return min < 512 ? 512 / min : 1
}

export const base64ToImgStr = base64 =>  base64.split(';base64,').pop()

export const imgStrToBase64 = img => `data:image/png;base64,${img}`

export const saveImage = (images) => {
    if (images.length === 0 ) return null

    let link = document.createElement("a")
    link.download = "aisketch-image.png"
    link.href = images[0].image.src
    link.click()
}

export const drawCanvasExport = (values, bounds, scaleFactor, image = null) => {
    if (values.length === 0 && image === null) return null

    const { x, y, w, h } = bounds

    const canvas = document.createElement("canvas")
    const context = scaleCanvas(canvas, w, h, scaleFactor)
    context.translate(-x, -y)

    context.fillStyle = image ? "white" : "black"
    context.fillRect(x, y, w, h)

    if (image && image.bounds) {
        const imgBounds = image.bounds
        context.fillStyle = "black"
        context.filter = `blur(${BLUR_EDGE}px)`
        context.fillRect(imgBounds.x + (BLUR_EDGE / 2), imgBounds.y + (BLUR_EDGE / 2), imgBounds.w - BLUR_EDGE, imgBounds.h - BLUR_EDGE)
        context.filter = "none"
    }

    if (values[0]?.image) {
        drawImage(context, values[0].image, values[0].bounds)
    } else {
        values.forEach((stroke) => {
            drawStroke(context, stroke, image ? 1 : 0)
        })
    }
    
    return base64ToImgStr(canvas.toDataURL("image/png"))
}


export const sendToEndpoint = async (url, body) => {

    try {
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
            },
                body: JSON.stringify(body),
            }
        )

        const result = await response.json();
        
        return {
            type: "success",
            payload: JSON.parse(result.payload)
        }
        
    } catch (error) {
        return {
            type: "error",
            payload: error
        }
    }
}
