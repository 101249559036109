import { useEffect } from "react"
import { isHotkeyPressed } from "../CanvasUtils"

export default function HotkeyItem ({hotkeyPressed, setHotkeyPressed, hotkey, setAnimate, onPressAction = () => {}}) {
    useEffect(() => {
        if (isHotkeyPressed(hotkeyPressed, hotkey)) {
            onPressAction(hotkey.action)
            setHotkeyPressed(null)
            setAnimate({ shouldAnimate: true, aniKey: Math.random() })
        } else if (hotkeyPressed !== null) {
            setAnimate(prev => ({ shouldAnimate: false, ...prev }))
        }
    }, [hotkeyPressed])

    return (
        <div className="hotkey-label">
            {hotkey?.label}
        </div>
    )
}