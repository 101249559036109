import { useEffect, useState } from "react"
import { ART_STYLES } from "../Constants"
import "./Dropdown.css"

export default function Dropdown ({artStyle, setArtStyle, selector, setDontBlur, setShouldBlur}) {
    const [label, setLabel] = useState("")

    useEffect(() => {
        setLabel(ART_STYLES.filter(s => s.value === artStyle)[0]?.label)
    }, [artStyle])

    const onChange = (e) => {
        setArtStyle(e.target.value)
    }


    return (
        <div className="dropdown">
                {label}
            <div className="tick" />
            <select
                value={artStyle}
                onChange={onChange}
                ref={selector}
                onBlur={() => {
                    setShouldBlur(true)
                    setDontBlur(false)
                }}
                onMouseDown={() => setDontBlur(true)}
            >
                {ART_STYLES.map(o => <option 
                    value={o.value}
                    key={o.value}
                >
                    {o.label}
                </option>)}
            </select> 
            
        </div>
    )    
}