import "./CanvasScroller.css"

export default function CanvasScroller ({scrollCanvasRef, canvasSize, onCanvasDown, onCanvasUp, onCanvasMove, scrollbarHorizontal, scrollbarVertical}) {

    return (
        <div 
            ref={scrollCanvasRef}
            className="canvas-scroll"
            style={{width:`${canvasSize.width}px`, height:`${canvasSize.height}px`}}
        >
            <div
                className="scrollbar-vertical"
                onMouseDown={e => onCanvasDown(e, true, false, true)}
                onMouseUp={e => onCanvasUp(e, true)}
                onMouseMove={e => onCanvasMove(e, true, false, true)}
                onMouseLeave={onCanvasUp}
                onMouseOut={onCanvasUp}
            >
                <div className="scroll-handle" ref={scrollbarVertical}></div>
            </div>
            <div
                className="scrollbar-horizontal"
                onMouseDown={e => onCanvasDown(e, false, true, true)}
                onMouseUp={e => onCanvasUp(e, true)}
                onMouseMove={e => onCanvasMove(e, false, true, true)}
                onMouseLeave={onCanvasUp}
                onMouseOut={onCanvasUp}
            >
                <div className="scroll-handle" ref={scrollbarHorizontal}></div>
            </div>
        </div>
    )
}