import { useRef, useState, useEffect } from "react"
import {DEFAULT_BRUSHSIZES, HOTKEY_ANIMATE_DEFAULT } from "../Constants"
import HotkeyItem from "./HotkeyItem"

export default function ToolItem ({icon, type, toolAction, active, setBrushsize = () => {}, hotkeyPressed, setHotkeyPressed, hotkey, onOpenMenu = () => {}}) {

    const itemRef = useRef()
    const [submenu, setSubmenu] = useState(type === "brush" || type === "erase")
    const [animate, setAnimate] = useState(HOTKEY_ANIMATE_DEFAULT)

    const onAction = () => {
        toolAction(type)
        setBrushsize(DEFAULT_BRUSHSIZES[type])

        const top = itemRef.current.offsetTop
        if (submenu) onOpenMenu(type, top)
    }

    return (
        <div 
            key={`${type} ${animate.aniKey}`}
            ref={itemRef}
            className={`tool-item ${active && "active"} ${animate.shouldAnimate && "flash"}`}
        >
            <button className="tool-btn">
                <div className="icon"
                    style={{maskImage: `url(./img/${icon})`}}
                    onClick={onAction}
                ></div>
                <HotkeyItem
                    hotkeyPressed={hotkeyPressed}
                    setHotkeyPressed={setHotkeyPressed}
                    setAnimate={setAnimate}
                    onPressAction={onAction}
                    hotkey={hotkey}
                />
            </button>
            
        </div>
    )
}