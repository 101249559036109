import "./AppView.css"
import Cover from "./Cover"
import SketchBook from "./SketchBook"
import {useEffect, useState} from "react"
import {sendToEndpoint} from "../ExportUtils"
import { SERVER_URL } from "../Constants"

export default function AppView() {
    const [showCover, setShowCover] = useState(true)

    useEffect(() => {
        initServer()
    }, [])

    const initServer = async () => {
        const result = await sendToEndpoint(SERVER_URL + "/init",  {
            mode: null,
            prompt: null,
            type: null,
            width: null,
            height: null,
            scribbles: null,
            segments: null,
            images: null,
            inpaints: null,
        })

        if (result.payload.status === "COMPLETED") {
            const parsedResult = JSON.parse(result.payload.output)
            console.log(parsedResult)
        } else {
            console.log(result.payload.status)
        }
    }

    return (
        <div className="screen">
            {showCover && <Cover 
                setShowCover={setShowCover}
            />}
            <SketchBook />
        </div>
    )
}