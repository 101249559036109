import "./SketchPad.css"
import Canvas from "./Canvas"
import { shouldEatInput, heldKeys } from "../CanvasUtils"
import { ACTION_HOTKEYS, TOOL_HOTKEYS } from "../Constants"

import { useState, useEffect, useCallback } from "react"

export default function SketchPad ({disablePointerEvents, scrollCanvasRef, color, brushsize, toolType, canvasRef, hotkeyAction, setShowActionBar, isGenerate, errorMsg, setErrorMsg}) {

    const keyPress = useCallback(e => handleKeyPress(e))

    useEffect(() => {
        window.addEventListener("keyup", keyPress)
        return () => window.removeEventListener("keyup", keyPress)
    }, [keyPress])

    
    const handleKeyPress = (e) => {
        
        if (shouldEatInput(document.activeElement)) return

        const hotkey = [...TOOL_HOTKEYS, ...ACTION_HOTKEYS].filter(tool =>  e.key === tool.key && heldKeys(e, tool.held))
        
        hotkeyAction(hotkey.length > 0 ? hotkey[0] : null)
    }

    return (
        <div
            className="sketchpad"
        >
            <Canvas
                ref={canvasRef}
                disablePointerEvents={disablePointerEvents}
                scrollCanvasRef={scrollCanvasRef}
                color={color}
                toolType={toolType}
                brushsize={brushsize}
                canvasRef={canvasRef}
                setShowActionBar={setShowActionBar}
                isGenerate={isGenerate}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
            />
        </div>
    )
}