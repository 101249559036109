import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import AppView from './Components/AppView';

const App = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={AppView} />
      </Switch>
    </Router>
  );
};

export default App;
