import { useEffect, useRef, useState } from "react"
import "./Toolbar.css"
import { BRUSHSIZES, TOOLTYPES } from "../Constants"
import ToolItem from "./ToolItem"
import OnClickOutside from "./OnClickOutside"

function BrushSizeItem ({size, color, active, setBrushsize}) {

    const onClick = () => setBrushsize(size)

    return (
        <div className={`brushsize-item ${active && "active"}`}
            onClick={onClick}
        >
            <div className="brush-visual"
                style={{width: `${size}px`, height: `${size}px`, background: color}}
            />
        </div>
    )
}

export default function Toolbar ({toolbarRef, color, setColor, toolType, setToolType, brushsize, setBrushsize, hotkeyPressed, setHotkeyPressed}) {
    
    const colorInputRef = useRef()
    const menuRef = useRef(null)

    const [openMenu, setOpenMenu] = useState(false)
    const [menuType, setMenuType] = useState("")
    const [menuTop, setMenuTop] = useState(null)

    const toolAction = (type) => {
        setToolType(type)
    }

    const onOpenMenu = (type, top) => {
        setOpenMenu(true)
        setMenuType(type)
        setMenuTop(top)
    }

    OnClickOutside(menuRef, () => setOpenMenu(false))

    return (
        <div className="toolbar"
            ref={toolbarRef}
        >
            <div className="tool-item active overflow-hidden">
                <input className="color-input" type="color" 
                    ref={colorInputRef}
                    value={color}
                    onChange={e => setColor(e.target.value)}
                    style={{background: color, color: color}}
                />
            </div>
            {TOOLTYPES.map(t => (<ToolItem
                key={t.type}
                icon={t.icon}
                type={t.type}
                active={toolType === t.type}
                toolAction={toolAction}
                setBrushsize={setBrushsize}
                hotkeyPressed={hotkeyPressed}
                hotkey={t.hotkey}
                setHotkeyPressed={setHotkeyPressed}
                onOpenMenu={onOpenMenu}
            />))}
            {openMenu && <div className={`brushsize-menu`}
                ref={menuRef}
                style={{top: `${menuTop}px`}}
            >
                {BRUSHSIZES.map(b => <BrushSizeItem 
                    size={b}
                    key={b} 
                    color={menuType === "erase" ? "white" : color}
                    active={b === brushsize}
                    setBrushsize={setBrushsize}
                />)}
            </div>}
        </div>
    )
}