export function imageLoad(path) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.crossOrigin = 'Anonymous' // to avoid CORS if used with Canvas
        img.src = path
        img.onload = () => {
          resolve(img)
        }
        img.onerror = e => {
          reject(e)
        }
    })
}

export const getMode = (canvasData) => {
  const { images, scribbles, segments } = canvasData

  if (images && (scribbles || segments))
      return "inpainting_controlnet"
  else if (images === null)
    return "controlnet"
  else
    return "inpainting"
}

export const validatePrompt = (arg) => {
  if (arg.prompt === "") return {
    msg: "Prompt is missing",
    valid: false
  }

  if (arg.scribbles || arg.segments) {
    return {
      msg: "Has drawings",
      valid: true
    }
  }

  if (arg.images && arg.inpaints) {
    return {
      msg: "Has inpainting",
      valid: true
    }
  }

  return {
    msg: "Has not drawings or paintings",
    valid: false
  }
}