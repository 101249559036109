import Dropdown from "./Dropdown"
import "./PromptBox.css"
import { useRef, useState, useEffect } from "react"

export default function PromptBox ({promptboxRef, setErrorMsg, isGenerate, generateImage, prompt, setPrompt, artStyle, setArtStyle}) {
    const inputbox = useRef(null)
    const selector = useRef(null)
    const [isFocused, setIsFocused] = useState()
    const [dontBlur, setDontBlur] = useState(false)
    const [shouldBlur, setShouldBlur] = useState(false)

    useEffect(() => {
        if (dontBlur) {
            setDontBlur(false)
        }
    }, [dontBlur])

    useEffect(() => {
        if (shouldBlur){
            setShouldBlur(false)
            onBlur()
        }
    }, [shouldBlur])

    const onChange = (e) => {        
        if (!isGenerate) {
            setPrompt(e.target.value)
        } 
    }
     
    const onSubmit = () => {
        generateImage()
    }

    const onFocus = (e) => {
        e?.stopPropagation()
        setIsFocused(true)
        setErrorMsg(null)
    }

    const onBlur = (e) => {
        if (prompt === "" && !dontBlur && document.activeElement.className != "prompt-input") {
            setIsFocused(false)
        }
    }
    
     const onKeyDown = e => {
        
        if(e.keyCode === 13 && e.shiftKey === false) {
            onSubmit()
        }
     
    }

    return (
        <div className={`prompt-box ${isFocused && "focus"} ${isGenerate && "generating"}`}
            ref={promptboxRef}
        >
            <textarea
                ref={inputbox}
                placeholder="Prompt..."
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                value={prompt}
                disabled={isGenerate}
                className="prompt-input"
            />
            <button className={`icon-btn magic ${isGenerate && "loading"}`}
                disabled={isGenerate}
                onClick={onSubmit}
            >
                <div className="icon star" 
                    style={{maskImage: `url(./img/icon-star.svg)`}}
                />
                <div className="icon star" 
                    style={{maskImage: `url(./img/icon-star.svg)`}}
                />
            </button>
            <Dropdown 
                artStyle={artStyle}
                setArtStyle={setArtStyle}
                selector={selector}
                setDontBlur={setDontBlur}
                setShouldBlur={setShouldBlur}
            />
        </div>
    )
}