import { useEffect, useState } from "react"
import "./ActionBar.css"
import { CANVAS_ACTIONS, HOTKEY_ANIMATE_DEFAULT } from "../Constants"
import HotkeyItem from "./HotkeyItem"

function ActionButton ({button, performAction, hotkeyPressed, setHotkeyPressed}) {
    const [animate, setAnimate] = useState(HOTKEY_ANIMATE_DEFAULT)
    
    const onAction = () => {
        performAction(button.hotkey.action)
    }

    return (
        <button
            key={`${button.hotkey.key} ${animate.aniKey}`}
            className={`action-btn ${animate.shouldAnimate && "flash"}`}
            onClick={onAction}
        >
            {button.label}
            <HotkeyItem 
                hotkeyPressed={hotkeyPressed}
                setHotkeyPressed={setHotkeyPressed}
                hotkey={button.hotkey}
                setAnimate={setAnimate}
            />
        </button>
    )
}

export default function ActionBar ({hotkeyPressed, setHotkeyPressed, performAction, actionBarRef, showActionBar, isGenerate}) {
    return (
        <div className={`action-bar ${showActionBar && "show"} ${isGenerate && "generating"}`}
            ref={actionBarRef}
        >
            {CANVAS_ACTIONS.map(action => <ActionButton
                key={action.label}
                button={action}
                performAction={performAction}
                hotkeyPressed={hotkeyPressed}
                setHotkeyPressed={setHotkeyPressed}
            />)}
        </div>
    )
}