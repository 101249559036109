import { useState, useEffect } from "react";
import "./PrintBook.css"

export default function PrintBook ({ image, setPrintMode }) {
    const [isPortrait, setIsPortrait] = useState(false)

    let prevInit = 0
    useEffect(() => {
        
        if (image && prevInit === 0) {
            const { w, h } = image.bounds
            
            setIsPortrait(h > w)
            
            setTimeout(() => {
                window.print()
                setPrintMode(false)
            }, 10)
        }
        
        prevInit = 1
        
    }, [])


    return (
        <div className={`print-wrapper ${isPortrait && "portrait"}`}>
            <style type="text/css">
                {`@media print{@page {size: ${isPortrait ? "portrait" : "landscape"}}}`}
            </style>
            <img src={image.image.src} 
                style={{width: isPortrait ? "auto" : "100%", height: isPortrait ? "100%" : "auto"}}
            />
        </div>
    )  
}